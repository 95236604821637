<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Breeds</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Breeds"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.breedForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Breed</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="breeds"
            no-data-text="No breeds"
            :items-per-page="-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                :to="{
                  name: 'module-lhl-breeds-individual',
                  params: { breedId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip> -->

              <v-btn
                bottom
                x-small
                depressed
                color="red lighten-4 red--text"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <BreedForm ref="breedForm" />
    <v-dialog
      scrollable
      v-model="deleteBreed.dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Archive Breed</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteBreed.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BreedForm from "./components/BreedForm";

export default {
  components: {
    BreedForm,
  },

  data() {
    return {
      deleteBreed: {
        dialog: false,
        breed: {},
        loading: false,
      },

      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "Title", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    breeds() {
      let breeds = this.$store.state.lhl.breeds["breeds"];

      if (this.searchTerm !== "") {
        breeds = breeds.filter((c) => {
          const name = c.name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm);
        });
      }

      return breeds;
    },
  },

  methods: {
    openDelete(breed) {
      this.deleteBreed.breed = breed;
      this.deleteBreed.dialog = true;
    },

    resetDelete() {
      this.deleteBreed.dialog = false;
      this.deleteBreed.breed = {};
      this.deleteBreed.loading = false;
    },

    saveDelete() {
      this.deleteBreed.loading = true;

      this.$store
        .dispatch("lhl/breeds/deleteBreed", {
          appId: this.$route.params.id,
          breedId: this.deleteBreed.breed.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteBreed.loading = false;
        });
    },
  },
};
</script>
